<template>
  <section id="iq-favorites" v-if="CountContentCategoryURLByLocale > 0">
    <div
      :class="{
        active: showImagePopup,
        disabled: !showImagePopup,
        imagePopup: true,
      }"
      id="imagePopup"
    >
      <div @click="closeContent">
        <i class="fa fa-times closeBtn"></i>
      </div>
      <img :src="imageContentPath" alt="" />
    </div>
    <b-container fluid>
      <b-row>
        <b-col sm="12" class="overflow-hidden">
          <div
            class="iq-main-header d-flex align-items-center justify-content-between" :dir="$i18n.locale == 'en' ? 'ltr' : 'rtl'"
          >
            <h4 class="main-title">
              {{ category[0]["content_category"]?.[$i18n.locale] }}
              <br />
            </h4>
            <router-link
              :to="'/category/' + categoryID"
              class="iq-view-all"
              v-if="$i18n.locale == 'en'"
              >View All
            </router-link>
            <router-link
              :to="'/category/' + categoryID"
              class="iq-view-all"
              v-if="$i18n.locale == 'ku'"
            >
              هەموو ببینە
            </router-link>
            <router-link
              :to="'/category/' + categoryID"
              class="iq-view-all"
              v-if="$i18n.locale == 'ar'"
              >عرض الكل</router-link
            >
          </div>
          <div class="upcoming-contens" style="font-family: Speda">
            <Slick
              class="favorites-slider list-inline row p-0 mb-0 iq-rtl-direction"
              ref="dSlick"
              :option="favOption"
            >
              <li

                class="slide-item"
                v-for="(item, index) in category"
                :key="index"

              >
                <div
                v-if="item.content_url[$i18n.locale] != null"
                  class="block-images position-relative"

                >
                  <div class="img-box">
                    <div v-if="item.content_type == 'Video'">

                      <!-- <img
                        v-if="item.content_thumbnail!=null"
                        :src="item.content_thumbnail"
                        class="img-fluid"
                        alt=""
                      /> -->
                      <img
                        v-if="item.content_category.en=='Hidden Camera'"
                        src="../../../../assets/images/hidden.jpg"
                        class="img-fluid"
                        alt=""
                      />

                      <img
                        v-else-if="item.content_category.en=='Standup Comedy'"
                        src="../../../../assets/images/standup.jpg"
                        class="img-fluid"
                        alt=""
                      />

                      <div class="block-description1">
                        <h6 class="iq-title">
                          <a @click="gotoPage('/detail/' + item.id)"> </a>
                        </h6>
                        <div class="hover-buttons" v-if="$i18n.locale == 'en'">
                          <a
                            @click="gotoPage('/detail/' + item.id)"
                            role="button"
                            class="btn btn-hover"
                            ><i class="fa fa-play mr-1" aria-hidden="true">
                              Play Now
                            </i>
                          </a>
                        </div>
                        <div class="hover-buttons"  v-if="$i18n.locale == 'ku'">
                          <a
                            @click="gotoPage('/detail/' + item.id)"
                            role="button"
                            class="btn btn-hover"
                          >
                            <i class="fa fa-play mr-1" aria-hidden="true"> </i>
                            <span style="font-family: 'Speda'; font-size: 12px">
                              ڤیدیۆکە لێبدە
                            </span>
                          </a>
                        </div>
                        <div class="hover-buttons" v-if="$i18n.locale == 'ar'">
                          <a
                            @click="gotoPage('/detail/' + item.id)"
                            role="button"
                            class="btn btn-hover"
                          >
                            <i class="fa fa-play mr-1" aria-hidden="true"> </i>
                            <span
                              style="font-family: 'Janna-LT'; font-size: 12px"
                            >
                              شغل الفيديو
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>


                    <div v-if="item.content_type == 'Audio'">

                      <!-- <img
                        v-if="item.content_thumbnail!=null"
                        :src="item.content_thumbnail"
                        class="img-fluid"
                        alt=""
                      /> -->
                      <img
                        v-if="item.content_thumbnail!=null"
                        src="../../../../assets/images/thumbnail.jpeg"
                        class="img-fluid"
                        alt=""
                      />

                      <img
                        v-else
                        src="../../../../assets/images/thumbnail.jpeg"
                        class="img-fluid"
                        alt=""
                      />

                      <div class="block-description1">
                        <h6 class="iq-title">
                          <a @click="gotoPage('/detail/' + item.id)"> </a>
                        </h6>
                        <div class="hover-buttons" v-if="$i18n.locale == 'en'">
                          <a
                            @click="gotoPage('/detail/' + item.id)"
                            role="button"
                            class="btn btn-hover"
                            ><i class="fa fa-play mr-1" aria-hidden="true">
                              Play Now
                            </i>
                          </a>
                        </div>
                        <div class="hover-buttons"  v-if="$i18n.locale == 'ku'">
                          <a
                            @click="gotoPage('/detail/' + item.id)"
                            role="button"
                            class="btn btn-hover"
                          >
                            <i class="fa fa-play mr-1" aria-hidden="true"> </i>
                            <span style="font-family: 'Speda'; font-size: 12px;position: relative;top: -10px;">
                               سروودەکە لێبدە
                            </span>
                          </a>
                        </div>
                        <div class="hover-buttons" v-if="$i18n.locale == 'ar'">
                          <a
                            @click="gotoPage('/detail/' + item.id)"
                            role="button"
                            class="btn btn-hover"
                          >
                            <i class="fa fa-play mr-1" aria-hidden="true"> </i>
                            <span
                              style="font-family: 'Janna-LT'; font-size: 12px"
                            >
                              شغل أناشيد
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>



                    <div v-if="item.content_type == 'Image'">
                      <img
                        :src="item.content_thumbnail"
                        class="img-fluid"
                        alt="image"
                        style="filter: blur(2px) !important;"
                      />
                      <div class="block-description1">
                        <h6 class="iq-title">
                          <a @click="gotoPage('/detail/' + item.id)"> </a>
                        </h6>
                        <div class="hover-buttons" v-if="$i18n.locale == 'en'">
                          <a
                            @click="gotoPage('/detail/' + item.id)"
                            role="button"
                            class="btn btn-hover"
                            ><i class="fa fa-play mr-1" aria-hidden="true">
                              View
                            </i>
                          </a>
                        </div>
                        <div class="hover-buttons" v-if="$i18n.locale == 'ku'">
                          <a
                            @click="gotoPage('/detail/' + item.id)"
                            role="button"
                            class="btn btn-hover"
                          >
                            <i class="fa fa-play mr-1" aria-hidden="true"> </i>
                            <span style="font-family: 'Speda'"> بینین </span>
                          </a>
                        </div>
                        <div class="hover-buttons" v-if="$i18n.locale == 'ar'">
                          <a
                            @click="gotoPage('/detail/' + item.id)"
                            role="button"
                            class="btn btn-hover"
                          >
                            <i class="fa fa-play mr-1" aria-hidden="true"></i>
                            <span style="font-family: 'Janna-LT'">عرض </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </Slick>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>
<script>
import ApiService from "../../../../services/api"
import AlertServie from "../../../../services/errors"

export default {
  name: "Itemx",
  props: ["category", "categoryID"],
  components: {},
  mounted() {},
  data() {
    return {
      showImagePopup: false,
      imageContentPath: "",
      favOption: {
        dots: false,
        arrows: true,
        infinite: true,
        speed: 300,
        autoplay: false,
        prevArrow:
          '<div class="slick-prev slick-arrow"><i class="fa fa-chevron-left"></i></div>',
        nextArrow:
          '<div class="slick-next slick-arrow"><i class="fa fa-chevron-right"></i></div>',
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      },
    }
  },



  methods: {
    closeContent() {
      this.imageContentPath = ""
      this.showImagePopup = false
      //  this.imagePopup = false
    },
    openContent(id) {
      this.showImagePopup = true
      var self = this
      ApiService.openContent(id).then((response) => {
        this.$store.commit("setPoints", response.data.user_points)
        self.imageContentPath = response.data.data.content_url.ku
      })
    },
    gotoPage(link) {
      var points = this.$store.state.points
      if (points > 0) {
        this.$router.push(link)
      } else {
        if (this.$i18n.locale === "en") {
          AlertServie.info(
            "You have used all your daily points. You will receive 3 more points tomorrow."
          )
        } else if (this.$i18n.locale === "ar") {
          AlertServie.info(
            ".لقد استخدمت كل نقاطك اليومية. ستحصل على 3 نقاط إضافية غدًا"          )
        } else {
          AlertServie.info(
            ".هەموو خاڵەکانی ڕۆژانەت بەکارهێناوە. بەیانی 3 خاڵی تر وەردەگریت"          )
        }
      }
    },
    next() {
      this.$refs.dSlick.next()
    },
    prev() {
      this.$refs.dSlick.prev()
    },
  },

  computed: {
    CountContentCategoryURLByLocale() {
      var count = 0
      for (var i = 0; i < this.category.length; i++) {
        if (this.category[i].content_url[this.$i18n.locale] != null) {
          count++
        }
      }
      return count
    },
  },
}
</script>

<style scoped lang="scss">
.tabs {
  overflow: hidden;
}

.block-description1 {
  position: absolute;
  left: 25px;
  bottom: 10px;
  z-index: 999;
  display: flex;
  justify-content: end;
  flex-direction: column;
}

.tab-nav {
  list-style-type: none;
  display: flex;
  margin-right: 1085px;
  background-color: #829cdc;
  border: 1px solid #212529;
  border-radius: 40px;
}

.tab-nav li {
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.tab-nav li:hover {
  background-color: #FED03A;
}

.tab-nav li.active {
  background-color: #ffffff;
  border-bottom: 2px solid #fafafa;
  color: #212529;
  border-radius: 40px;
}

.tab-content {
  padding: 20px;
  background-color: #212529;
  border-color: aqua;
}

.hover-buttons .btn {
  height: 50px;
  width: 130px;
}

.fa {
  font-size: 16px !important;
  padding-top: 10px;
}

.imagePopup.active,
.textPopup.active {
  display: block;
  z-index: 999;
}

.imagePopup {
  max-height: 80vh;
  display: none;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: rgb(66, 53, 53);
  width: fit-content;
  //  max-height: 80vh;
  aspect-ratio: 1/1;
  padding-top: 20px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  z-index: 999;
  overflow: hidden;

  @media (max-width: 768px) {
    width: calc(96vw);
    height: fit-content;
  }

  @media (min-width: 768px) {
    width: fit-content;
    height: fit-content;
  }

  .prev,
  .next {
    position: absolute;
    top: 50%;
    z-index: 999;
    cursor: pointer;
    font-weight: 1200;
    font-size: 40px;
    color: #ddd;
  }

  .prev {
    left: 20px;
    transform: translateY(-50%);
  }

  .next {
    right: 20px;
    transform: translateY(-50%);
  }

  img {
    width: 100%;
    height: 100%;
  }

  .closeBtn {
    position: absolute;
    top: 0px;
    right: 10px;
    color: red;
    font-size: 20px;
    z-index: 999;
    overflow: hidden;
    cursor: pointer;
  }
}
</style>
