<template>
  <div is="detail" >
    <div v-show="loading">
      <SkeletonLoader />
    </div>
    <div >
      <Items v-for="(category, key) in categoriesData" :category="category" :categoryID="key" :key="key" id="viewMore" />
    </div>
  </div>
</template>
<script>
  import {
    core
  } from '../../config/pluginInit'
  import Items from './Components/ListItems/ListItems'
  import SkeletonLoader from './Components/ListItems/ItemSkeleton'
  import ApiService from '../../services/api'
  export default {
    name: 'MainPage',
    data() {
      return {
        categories: [],
        loading: true,
      }
    },
    components: {
      Items,
      SkeletonLoader
    },
    methods: {

      async getContentCategories() {
       await ApiService.getLastContentCategories().then((response) => {
          this.$store.commit('setPoints', response.data.user_points)
          this.$store.commit('sethomePageCategory', response.data.data);
        })
      }
    },
    computed:{
      categoriesData(){
        return this.$store.getters.getCategory;
      }
    },
    mounted() {
      core.index()
      this.getContentCategories()
      this.categories=this.$store.getters
      setTimeout(() => {
        this.loading = false
      }, 1000)
    },
    beforeRouteEnter(to, from, next) {
      if (from.name === '/verify page') {
        setTimeout(() => {
          window.location.reload()
        }, 300)
      }
      next()
    },
  }
</script>

<style scoped>

</style>